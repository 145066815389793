import * as React from 'react';
import type { CategoriesViewProps } from '../../types';
import Container from '@mui/material/Container';
import { CollectionHoc } from './CollectionHoc';
import dynamic from 'next/dynamic';
import styled from '@emotion/styled';
import { useAppContext } from '../../contexts/AppContext';
import { useMediaQuery } from '@mui/material';
import theme from '../../app/theme';
import { LayoutTypeEnum } from '../../types/enum';
import { HOME_MIDDLE_ADS_CONFIGURATION } from '../../types/static';
import { BrowserView, MobileView } from 'react-device-detect';

interface CollectionItemProps {
  component?: {
    name: string;
    hideOnHomepage?: boolean;
  };
  games?: any[];
  weight?: number;
  [key: string]: any;
}

interface LayoutConfig {
  name: string;
  layouts?: Array<{
    imports?: Array<{
      component: string;
    }>;
  }>;
}

interface AdvertisementContainerProps {
  index: number;
  totallength: number;
  theme?: any;
}

const StyledAdDiv = styled('div')`
  box-shadow: ${props => props.theme.shadows[0]};
  width: fit-content;
  margin: auto;
`;

const DynamicComponents: Record<string, React.ComponentType<any>> = {
  SliderList: dynamic<any>(() => import('../../components/CollectionCommon/CarouselA').then((mod) => mod.SliderList), { ssr: false }),
  SliderListB: dynamic<any>(() => import('../../components/CollectionCommon/CarouselB').then((mod) => mod.SliderListB), { ssr: false }),
  SliderListC: dynamic<any>(() => import('../../components/CollectionCommon/CarouselC').then((mod) => mod.SliderListC), { ssr: false }),
  SliderListD: dynamic<any>(() => import('../../components/CollectionCommon/CarouselD').then((mod) => mod.SliderListD), { ssr: false }),
  FeatureGridA: dynamic<any>(() => import('../../components/CollectionCommon/FeatureGridA').then((mod) => mod.FeatureGridA), { ssr: false }),
  FeatureGridB: dynamic<any>(() => import('../../components/CollectionCommon/FeatureGridB').then((mod) => mod.FeatureGridB), { ssr: false }),
  BannerHorizontalA: dynamic<any>(() => import('../../components/CollectionCommon/BannerHorizontalA').then((mod) => mod.BannerHorizontalA), { ssr: false }),
  ListViewA: dynamic<any>(() => import('../../components/CollectionCommon/ListViewA').then((mod) => mod.ListViewA), { ssr: false }),
  PromotionBanner: dynamic<any>(() => import('../../components/CollectionCommon/PromotionBanner').then((mod) => mod.PromotionBanner), { ssr: false }),
  AdvertisementSlot: dynamic<any>(() => import('../../components/AdvertisementSlot').then((mod) => mod.AdvertisementSlot), { ssr: false })
};

const StyledAdvertisementSlot = styled(DynamicComponents.AdvertisementSlot)`
  box-shadow: ${props => props.theme.shadows[0]};
  width: fit-content;
  margin: auto;
`;

const AdvertisementContainer = styled('div')<AdvertisementContainerProps>`
  padding: 0;
  margin-bottom: ${props => 
    props.index === (props.totallength ?? 0) - 1 
      ? props.theme.spacing(6) 
      : props.theme.spacing(5)
  };
  margin-top: ${props => props.theme.spacing(5)};
  box-shadow: ${props => props.theme.shadows[0]};
`;

const determineLayoutType = (homePageLayout: string | undefined): LayoutTypeEnum => {
  if (!homePageLayout) return LayoutTypeEnum.DEFAULT;
  
  const layoutMap: Record<string, LayoutTypeEnum> = {
    'ConservativeHome': LayoutTypeEnum.CONSERVATIVE,
    'ModerateHome': LayoutTypeEnum.MODERATE,
    'AggressiveHome': LayoutTypeEnum.AGGRESSIVE
  };

  return layoutMap[homePageLayout] || LayoutTypeEnum.DEFAULT;
};

const shouldShowAd = (index: number, layout: LayoutTypeEnum, isMd: boolean, totalLength: number): boolean => {
  if (index === totalLength - 1) return false;
  if (layout === LayoutTypeEnum.CONSERVATIVE) return false;
  
  return isMd
    ? layout === LayoutTypeEnum.AGGRESSIVE ? index % 1 === 0 : index % 2 === 1
    : index % 3 === 2;
};

const CollectionItemRenderer: React.FC<{
  item: CollectionItemProps;
  template?: string;
}> = ({ item, template }) => {
  const componentName = item.component?.name || template || 'SliderList';
  const Component = DynamicComponents[componentName] || DynamicComponents.SliderList;

  return (
    <Component
      template={componentName}
      items={item.games}
      {...item}
    />
  );
};

const Advertisement: React.FC<{
  componentOptions: any;
  variant: string;
  index: number;
  totalLength: number;
}> = ({ componentOptions, variant, index, totalLength }) => {
  const browserAdHtml = `<pgs-ad data-pg-ad-spot='${componentOptions?.component_home_middle_id}'></pgs-ad>`;
  const mobileAdHtml = `<pgs-ad data-pg-ad-spot='${componentOptions?.component_home_mobile_middle_id}'></pgs-ad>`;

  return (
    <AdvertisementContainer index={index} totallength={totalLength}>
      <BrowserView>
        <StyledAdvertisementSlot 
          slotIdentifier={componentOptions?.component_home_middle_id} 
          variant="728x90" 
          adType="pgs"
        />
      </BrowserView>
      <MobileView>
        <StyledAdvertisementSlot
          slotIdentifier={componentOptions?.component_home_mobile_middle_id} 
          variant="320x250"
          adType="pgs"
        />
      </MobileView>
    </AdvertisementContainer>
  );
};

export const CollectionWrapper: React.FC<CategoriesViewProps> = (props: CategoriesViewProps) => {
  const { collections } = props;
  const appContext = useAppContext();
  const { componentOptions, pageOptions } = appContext.useConfig();
  const isMd = useMediaQuery(theme.breakpoints.up('md'));
  
  const homePageLayout = pageOptions.find((page: LayoutConfig) => 
    page.name === 'index'
  )?.layouts?.[0]?.imports?.[0]?.component;
  
  const layout = determineLayoutType(homePageLayout);
  
  const sortedCollections = React.useMemo(() => 
    (collections as CollectionItemProps[])
      .sort((a, b) => ((a.weight ?? 0) - (b.weight ?? 0)))
      .filter(item => !item.component?.hideOnHomepage),
    [collections]
  );

  const variant = isMd ? '728x90' : !isMd && layout === LayoutTypeEnum.AGGRESSIVE ? '300x250' : '320x100';

  return (
    <Container maxWidth={false} style={{ marginTop: '40px' }}>
      {sortedCollections.map((collectionItem, index) => (
        <React.Fragment key={index}>
          <CollectionItemRenderer item={collectionItem} />
          
          {shouldShowAd(index, layout, isMd, sortedCollections.length) && (
            <Advertisement
              componentOptions={componentOptions}
              variant={variant}
              index={index}
              totalLength={sortedCollections.length}
            />
          )}
        </React.Fragment>
      ))}
    </Container>
  );
};

const Collection = CollectionHoc(CollectionWrapper);

export { Collection };